import axios from 'axios'
import sdk, { IndexedDBCryptoStore, MatrixClient } from 'matrix-js-sdk'
import { useStore } from 'vuex'
import { importIpcRenderer, ipcRenderer } from './config/PlatformConfig'
import { MatrixClientCreds } from './modules/chats/interfaces/MatrixClientCreds'

importIpcRenderer()

export let globalMatrixClient: MatrixClient

export async function login () {
  let matrixCreds = JSON.parse(localStorage.getItem('matrixCreds')!) || null
  let loginToken = null

  if (!matrixCreds) {
    if (window.location.search.includes('loginToken')) {
      loginToken = window.location.search.slice(window.location.search.indexOf('=') + 1)
    }

    if (!loginToken) {
      if (ipcRenderer) ipcRenderer.invoke('SET_BACKGROUND_COLOR', '#ffffff')
      window.open(`https://${process.env.VUE_APP_MATRIX_HOMESERVER}/` +
                  `${process.env.VUE_APP_ELEMENT_LOGIN_URL}${window.location.href}`, '_self')
    } else {
      const response = await axios.post(`https://${process.env.VUE_APP_MATRIX_HOMESERVER}/_matrix/client/r0/login`, {
        type: 'm.login.token',
        token: loginToken
      })

      matrixCreds = {
        homeserverUrl: 'hsUrl',
        identityServerUrl: 'isUrl',
        userId: response.data.user_id,
        deviceId: response.data.device_id,
        accessToken: response.data.access_token
      }

      localStorage.setItem('matrixCreds', JSON.stringify(matrixCreds))
      localStorage.setItem('mx_access_token', response.data.access_token)
      // window.location.replace(oauthRedicetUri())
      await init(matrixCreds)
    }
  } else {
    await init(matrixCreds)
  }
}

async function init (matrixCreds: MatrixClientCreds) {
  const indexedDBStore = window.indexedDB ? new sdk.IndexedDBStore({
    indexedDB: window.indexedDB,
    localStorage: window.localStorage,
    dbName: 'matrix-chat-sync'
  }) : null
  indexedDBStore!.startup()

  const client = sdk.createClient({
    baseUrl: `https://${process.env.VUE_APP_MATRIX_HOMESERVER}`,
    accessToken: localStorage.getItem('mx_access_token')!,
    userId: matrixCreds.userId,
    sessionStore: window.localStorage,
    cryptoStore: new sdk.MemoryCryptoStore(),
    // cryptoStore: new IndexedDBCryptoStore(window.indexedDB, 'matrix-js-sdk:crypto'),
    deviceId: matrixCreds.deviceId,
    unstableClientRelationAggregation: true,
    timelineSupport: true,
    /** TODO: Add a turn server and remove the below line. Read more about WebRTC. */
    fallbackICEServerAllowed: true
  })

  client.setMaxListeners(500)

  let isReady = false
  const store = useStore()
  client.on('sync', async function (state, prevState, res) {
    if (state !== 'PREPARED') return
    client.setGlobalErrorOnUnknownDevices(false)
    isReady = true
  })

  await client.initCrypto()
  client.startClient({ initialSyncLimit: 30 })
  globalMatrixClient = client
  // client.crypto.cancelAndResendAllOutgoingKeyRequests()
}
